export default {
  path: "/organization",
  name: "organization",
  component: () => import("@/views/LayoutView.vue"),
  children: [
    {
      path: "",
      redirect: "my",
    },
    {
      path: "my",
      props: {
        getDataUrl: "/my",
      },
      component: () => import("@/components/default/view/DefaultView.vue"),
    },

    {
      path: ":dir(staff)/(list)*",
      component: () => import("@/views/organization/staff/StaffList.vue"),
    },

    // {
    //     path: ':dir(group-gov)/(list)*',
    //     component: () => import('@/views/organization/group-gov/GroupGovList.vue')
    // },
    {
      path: ":dir(journal)/(list)*",
      component: () =>
        import("@/views/gos-organization/journal/JournalListFilter.vue"),
    },

    {
      path: ":dir(application)/(list)*",
      component: () =>
        import("@/views/organization/applications/ApplicationsList.vue"),
    },
    {
      path: ":dir(groupe)/view/:id(all)",
      component: () => import("@/components/default/view/DefaultView.vue"),
    },

    {
      path: ":dir(groupe)/view/:id",
      component: () =>
        import("@/views/organization/group-gov/GroupGovView.vue"),
    },

    {
      path: ":dir(place)/:subdir(submited)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "place?status_id=1",
      },
    },
    {
      path: ":dir(place)/:subdir(approved)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "place?status_id=2",
      },
    },
    {
      path: ":dir(place)/:subdir(rejected)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "place?status_id=3",
      },
    },
    {
      path: ":dir(place)/:subdir(revoked)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "place?status_id=4",
      },
    },

    {
      path: ":dir(remark)/(list)*",
      component: () =>
        import("@/views/organization-admin/journal/JournalList.vue"),
    },
    {
      path: ":dir(journal)/view/:id",
      component: () =>
        import("@/views/organization-admin/journal/JournalView.vue"),
    },
    {
      path: ":dir/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
    },
    {
      path: ":dir/view/:id",
      component: () => import("@/components/default/view/DefaultView.vue"),
    },
  ],
};
