import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { VueMaskDirective } from 'v-mask'
import axios from 'axios'
import './axios.config.js'
import router from './router'
import store from './store'
import i18n from './i18n'

const params = new URLSearchParams(location.search);
if (params.has("token") && params.has("name")) {
	const loginType = 'parent';
    store
    .dispatch("login", {
      loginType: loginType,
      token: params.get("token"),
      user: {
        name: params.get("name")
      },
      otherAccounts: []
    })
    .then(() => {
		location.href = "/"
    //   router.push({ path: "/" + loginType });
    });
}


Vue.config.productionTip = true
Vue.prototype.$http = axios;

i18n.locale = store.getters.getLanguage;
Vue.directive('mask', VueMaskDirective);

new Vue({
	vuetify,
	// vmask,
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
