module.exports = {
  place: "Объявление о конкурсе",
  commision: "Председатель комиссии",
  organization: "Организации",
  apply: "Участвующие в конкурсе",
  plan: "Учебный план",
  staff: "Сотрудники",
  branch: "Объекты",
  training: "Курсы повышения",
};
