import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import config from "./config";
import router from "./router";
//import i18n from './i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: localStorage.getItem("accessToken"),
    alerts: [],
    clickRoute: "course",
    language: localStorage.getItem("language") || "ru",
    currentRole: localStorage.getItem("currentRole") || "",
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    otherAccounts: JSON.parse(localStorage.getItem("otherAccounts")) || [],
    myChildren: JSON.parse(localStorage.getItem("myChildren")) || [],
    currentChild: JSON.parse(localStorage.getItem("currentChild")) || "",
    isPpp: localStorage.getItem("isPpp") || "0",
  },

  mutations: {
    alert(state, { alert, adding }) {
      adding ? state.alerts.push(alert) : state.alerts.shift();
    },
    setLanguage(state, lang) {
      localStorage.setItem("language", lang);
      state.language = lang;
      //i18n.locale = lang;
    },
    setToken(state, token) {
      state.accessToken = token;
      localStorage.setItem("accessToken", state.accessToken);
      axios.defaults.headers.common["X-Auth"] = "Bearer " + state.accessToken;
    },
    setIsPpp(state, payload) {
      state.isPpp = payload;
      localStorage.setItem("isPpp", payload);
    },
    setCurrentRole(state, role) {
      localStorage.setItem("currentRole", role);
      state.currentRole = role;
    },
    setCurrentChild(state, child) {
      localStorage.setItem("currentChild", JSON.stringify(child));
      state.currentChild = child;
    },
    setMyChildren(state, children) {
      localStorage.setItem("myChildren", JSON.stringify(children));
      state.myChildren = children;
    },
    setOtherAccounts(state, accounts) {
      localStorage.setItem("otherAccounts", JSON.stringify(accounts));
      state.otherAccounts = accounts;
    },
    logout(state) {
      state.accessToken = "";
      state.userInfo = {};
      state.otherAccounts = [];
      state.currentRole = "";
      state.currentChild = "";
      state.myChildren = [];
      localStorage.clear();
      delete axios.defaults.headers.common["X-Auth"];
    },
    clickRoute(state, route) {
      state.clickRoute = route;
      console.log(state.clickRoute);
    },
  },

  actions: {
    login({ dispatch, state, commit }, data) {
      commit("setToken", data.token);
      localStorage.setItem("userInfo", JSON.stringify(data.user));
      state.userInfo = data.user;
      commit("setOtherAccounts", data.otherAccounts);
      dispatch("setCurrentRole", data.loginType);
    },
    changeToken({ commit }, token) {
      commit("setToken", token);
      router.go();
    },
    setCurrentRole({ commit, dispatch }, role) {
      commit("setCurrentRole", role);
      dispatch("changeBaseUrl");
    },
    setCurrentChild({ commit, dispatch }, child) {
      commit("setCurrentChild", child);
      dispatch("setCurrentChildToAxios", child);
    },
    // eslint-disable-next-line
    setCurrentChildToAxios({}, child) {
      axios.defaults.params = {};
      axios.defaults.params["child_id"] = child.id;
      // console.log(child);
    },
    setMyChildren({ commit }, children) {
      commit("setMyChildren", children);
    },
    changeBaseUrl({ getters, state }) {
      axios.defaults.baseURL =
        getters.getBaseUrl +
        getters.getApiVersion +
        (state.currentRole
          ? "/" + state.language + "/" + state.currentRole
          : "");
    },
    alertAdd({ commit }, alert) {
      commit("alert", { alert, adding: true });
      setTimeout(() => commit("alert", { alert, adding: false }), 5000);
    },
    logout({ commit, dispatch }) {
      let history = router.history.pending ?? router.history.current;
      console.log(history);
      commit("logout");
      dispatch("changeBaseUrl");
      //let url = '/#/login'+(history.matched[0] ? '/'+history.matched[0].name : '')+(history.fullPath != '/' ? '?url='+encodeURIComponent(history.fullPath) : '');
      let url = "/#/login";

      location.href = url;
      //location.reload();
      //location.href = '/portal/#/login'+(history.matched[0] ? '/'+history.matched[0].name : '')+(history.fullPath != '/' ? '?url='+encodeURIComponent(history.fullPath) : '');
    },
  },

  getters: {
    getBaseUrl() {
      return config.API_URL;
    },
    getApiVersion() {
      return config.API_VERSION;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getCurrentRole(state) {
      return state.currentRole;
    },
    getCurrentChild(state) {
      return state.currentChild;
    },
    getMyChildren(state) {
      return state.myChildren;
    },
    getOtherAccounts(state) {
      return state.otherAccounts;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    getLanguage(state) {
      return state.language;
    },
  },
});
