export default {
  path: "/superadmin",
  name: "superadmin",
  component: () => import("@/views/LayoutView.vue"),
  children: [
    {
      path: "",
      redirect: "accounts",
    },

    {
      path: ":dir(accounts)/view/:id",
      component: () => import("@/views/superadmin/roles/RoleList.vue"),
      props: route => ({
        getDataUrl: `/accounts/${route.params.id}`,
      }),
    },

    {
      path: ":dir(organizations)/(list)*",
      component: () => import("@/views/superadmin/organizations/OrganizationsList.vue"),
    },

    {
      path: ":dir/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
    },

    {
      path: ":dir/view/:id",
      component: () => import("@/components/default/view/DefaultView.vue"),
    },

    // {
    //   path: "my",
    //   props: {
    //     getDataUrl: "/accounts/list",
    //   },
    //   component: () => import("@/components/default/view/DefaultView.vue"),
    // },

    // {
    //   path: ":dir(staff)/(list)*",
    //   component: () => import("@/views/organization/staff/StaffList.vue"),
    // },

    // {
    //     path: ':dir(group-gov)/(list)*',
    //     component: () => import('@/views/organization/group-gov/GroupGovList.vue')
    // },
    // {
    //   path: ":dir(journal)/(list)*",
    //   component: () => import("@/views/gos-organization/journal/JournalListFilter.vue"),
    // },

    // {
    //   path: ":dir(application)/(list)*",
    //   component: () => import("@/views/gos-organization/journal/JournalListFilter.vue"),
    // },
    // {
    //   path: ":dir(groupe)/view/:id(all)",
    //   component: () => import("@/components/default/view/DefaultView.vue"),
    // },

    // {
    //   path: ":dir(groupe)/view/:id",
    //   component: () => import("@/views/organization/group-gov/GroupGovView.vue"),
    // },

    // {
    //   path: ":dir(place)/:subdir(submited)/(list)*",
    //   component: () => import("@/components/default/list/DefaultList.vue"),
    //   props: {
    //     getDataUrl: "place?status_id=1",
    //   },
    // },
    // {
    //   path: ":dir(place)/:subdir(approved)/(list)*",
    //   component: () => import("@/components/default/list/DefaultList.vue"),
    //   props: {
    //     getDataUrl: "place?status_id=2",
    //   },
    // },
    // {
    //   path: ":dir(place)/:subdir(rejected)/(list)*",
    //   component: () => import("@/components/default/list/DefaultList.vue"),
    //   props: {
    //     getDataUrl: "place?status_id=3",
    //   },
    // },
    // {
    //   path: ":dir(place)/:subdir(revoked)/(list)*",
    //   component: () => import("@/components/default/list/DefaultList.vue"),
    //   props: {
    //     getDataUrl: "place?status_id=4",
    //   },
    // },

    // {
    //   path: ":dir(remark)/(list)*",
    //   component: () => import("@/views/organization-admin/journal/JournalList.vue"),
    // },
    // {
    //   path: ":dir(journal)/view/:id",
    //   component: () => import("@/views/organization-admin/journal/JournalView.vue"),
    // },
  ],
};
