import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../views/login/AuthPage.vue";
//import Home from '../views/Home.vue'
import store from "../store";
import Organization from "@/views/organization/router";
import OrganizationAdmin from "@/views/organization-admin/router";
import Department from "@/views/department/router";
import Commission from "@/views/commission/router";
import Staff from "@/views/staff/router";
import HeadDepartment from "@/views/head-department/router";
import Parent from "@/views/parent/router";
import Superadmin from "@/views/superadmin/router";
import GosOrganization from "@/views/gos-organization/router";
// gos - organization;
Vue.use(VueRouter);

const routes = [
  {
    path: "/login/:loginType*",
    name: "login",
    component: Auth,
  },
  Department,
  Commission,
  Organization,
  OrganizationAdmin,
  Staff,
  GosOrganization,
  HeadDepartment,
  Superadmin,
  Parent,
  {
    path: "*",
    component: () => import("../views/NotFound404.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    if (store.getters.getAccessToken) {
      next(store.getters.getCurrentRole);
    } else {
      next("/login/" + store.getters.getCurrentRole);
    }
  } else if (to.name == "login") {
    if (store.getters.getAccessToken) {
      next(store.getters.getCurrentRole);
    } else {
      next();
    }
  } else {
    if (store.getters.getAccessToken) {
      next();
    } else {
      store.dispatch("logout");
    }
  }
  // next();
});

export default router;
