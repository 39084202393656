<template>
  <v-app id="app">
    <app-alerts />
    <router-view />
  </v-app>
</template>


<script>
import AppAlerts from "./components/AppAlerts";

export default {
  name: "App",
  components: {
    AppAlerts,
  },

  created() {
    
  },

  computed: {},
  methods: {
  }
};
</script>

<style scoped>
::v-deep .dialog-to-top {
  align-self: flex-start;
}
::v-deep .card-title-custom {
    font-size: 15px;
    background-color: #f5f5f5;
    line-height: 1;
  }
::v-deep .card-text-custom {
    font-size: 15px;
    color: #000 !important;
  }
::v-deep .request-data .v-expansion-panel-header {
    background-color: #f5f5f5;
    min-height: initial;
    font-weight: 500;
  }
::v-deep .request-data .row + .row {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
::v-deep .data-table-in-view .v-card__title {
    font-size: 15px;
    background-color: #f5f5f5;
    line-height: 1;
    padding-bottom: 16px !important;
  }
</style>

<style>
.v-sheet.v-card {
    border-radius: 10px !important;
}
</style>
