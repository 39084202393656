export default {
  path: "/parent",
  name: "parent",
  props: true,
  component: () => import("@/views/parent/LayoutView.vue"),
  children: [
    {
      path: "",
      redirect: "child/list",
    },
    {
      path: ":dir(child)/list",
      component: () => import("@/views/parent/child/ChildList.vue"),
    },
    {
      path: ":dir(my-course)/view/:id",
      component: () => import("@/views/parent/JournalView.vue"),
    },
    {
      path: ":dir(gos-jurnal)/view/:id",
      component: () => import("@/views/parent/JournalView.vue"),
    },
    {
      path: ":dir(course)/view/:id",
      component: () => import("@/views/parent/course/CourseView.vue"),
    },
    {
      path: ":dir(course)/(list)*",
      component: () => import("@/views/parent/course/CourseList.vue"),
    },

    {
      path: ":dir(gos-course)/view/:id",
      component: () => import("@/views/parent/course/CourseView.vue"),
    },
    {
      path: ":dir(gos-course)/(list)*",
      component: () => import("@/views/parent/gos-course/GosCourseList.vue"),
    },
    {
      path: ":dir/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: true,
    },
    {
      path: ":dir/view/:id",
      component: () => import("@/components/default/view/DefaultView.vue"),
      props: true,
    },
  ],
};
