export default {
    path: '/commission',
    name: 'commission',
    props: true,
    component: () => import('@/views/LayoutView.vue'),
    children: [
      {
        path: '',
        redirect: 'place/list'
      },
      {
        path: ':dir(place)/view/:id',
        name: 'placeView',
        component: () => import('@/views/commission/place/PlaceView.vue'),
      },
      {
        path: ':dir(place)/:subdir(published)/(list)*',
        component: () => import('@/components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "place?status_id=2"
        }
      },
      {
        path: ':dir(place)/:subdir(archive)/(list)*',
        component: () => import('@/components/default/list/DefaultList.vue'),
        props: {
          getDataUrl: "place?status_id=3"
        }
      },
      {
        path: ':parentDir(place)/:placeId/:dir(apply)/view/:id',
        name: 'placeView',
        component: () => import('@/views/commission/place/apply/ApplyView.vue'),
      },
      {
        path: ':dir/(list)*',
        component: () => import('@/components/default/list/DefaultList.vue'),
        props: true,
      },
      {
        path: ':dir/view/:id',
        component: () => import('@/components/default/view/DefaultView.vue'),
        props: true,
      },
    ],
  }